import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppIconComponent } from './icon/icon.component';
import { ControlMessagesComponent } from './control-messages/control-messages.component';
import { ModalComponent } from './modal/modal.component';
import { SpinnerInputFormComponent } from './spinner-input-form/spinner-input-form.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ModalConfirmComponent } from './modal-confirm/modal-confirm.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalPreviewFullscreenComponent } from './modal-preview-fullscreen/modal-preview-fullscreen.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { CurtainComponent } from './curtain/curtain.component';
import { DocumentItemComponent } from './document-item/document-item.component';
import { DocumentItemTableComponent } from './document-item-table/document-item-table.component';
import { ImgItemComponent } from './img-item/img-item.component';
import { DirectivesModule } from '../directives/directives.module';
import { SelectCustomModule } from './select-custom/select-custom.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
  ],
  declarations: [
    AppIconComponent,
    ControlMessagesComponent,
    ModalComponent,
    SpinnerInputFormComponent,
    PaginationComponent,
    ModalConfirmComponent,
    ModalPreviewFullscreenComponent,
    SpinnerComponent,
    CurtainComponent,
    DocumentItemComponent,
    DocumentItemTableComponent,
    ImgItemComponent,
  ],
  exports: [
    AppIconComponent,
    ControlMessagesComponent,
    ModalComponent,
    SpinnerInputFormComponent,
    PaginationComponent,
    ModalConfirmComponent,
    ModalPreviewFullscreenComponent,
    SpinnerComponent,
    CurtainComponent,
    DocumentItemComponent,
    DocumentItemTableComponent,
    ImgItemComponent
  ]
})
export class ComponentsModule { }
