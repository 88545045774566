import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Modal } from 'bootstrap-italia';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, AfterViewInit {

  @Input() buttonLabel: string | undefined;
  @Input() modalTitle: string | undefined;
  @Input() modalId: string | undefined;
  @ViewChild('modal') modal: ElementRef | undefined;
  handlerModal: Modal | undefined;
  constructor() {}

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.handlerModal = new Modal(this.modal?.nativeElement);
    this.handlerModal?.show();
  }

  openModal() {
    this.handlerModal?.show();
  }

  closeModal() {
    this.handlerModal?.show();
  }

}