import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-document-item-table',
  templateUrl: './document-item-table.component.html',
  styleUrls: ['./document-item-table.component.scss']
})
export class DocumentItemTableComponent implements OnInit, OnChanges {
  header: any[] = [];
  body: { current: any[], filter: any[] } = {
    current: [],
    filter: []
  };


  @Input('data') set setData(value: any[]) {
    value = value || [];
    this.header = value[0] || [];
    if (this.header.length) {
      this.body.current = value.slice(1);
      this.body.filter = JSON.parse(JSON.stringify(this.body.current));
    }
  }

  @Input() search: string = '';

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['search']) {
      this.filterData(changes['search']?.currentValue);
    }
  }

  filterData(value: string): void {
    let current: string[][] = JSON.parse(JSON.stringify(this.body.current));
    current = current.filter(f => (value || '').length ? f.findIndex(f1 => (String((f1 || '')).toLowerCase()).includes(value.toLowerCase())) >= 0 : true);
    this.body.filter = current;
  }

}
