<ng-container [ngSwitch]="file?.state">

  <ng-container *ngSwitchCase="'SUCCESS'">
    <li class="cursor-pointer upload-file success" (click)="selectFile()">
      <app-icon name="file"></app-icon>
      <div class="cursor-pointer info-file">
        <span class="visually-hidden">File caricato:</span>
        <p [itTooltip]="file?.name">{{file?.name}}</p>
        <span class="upload-file-weight">{{getSize()}}</span>
      </div>
      <button disabled>
        <span class="visually-hidden">Caricamento ultimato</span>
        <app-icon name="check"></app-icon>
      </button>
    </li>
  </ng-container>

  <ng-container *ngSwitchCase="'UPLOADING'">
    <li class="cursor-pointer upload-file uploading" (click)="selectFile()">
      <app-icon name="file"></app-icon>
      <div class="cursor-pointer info-file">
        <span class="visually-hidden">Caricamento file:</span>
        <p [itTooltip]="file?.name">{{file?.name}}</p>
        <span class="upload-file-weight">{{getSize()}}</span>
      </div>
      <button>
        <span class="visually-hidden">Annulla caricamento {{ file?.name }}</span>
        <app-icon name="close"></app-icon>
      </button>
      <div class="progress progress-image">
        <div class="progress-bar" role="progressbar" [ngStyle]="getProgress()" [attr.aria-valuenow]="progress || 0"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </li>
  </ng-container>

  <ng-container *ngSwitchCase="'ERROR'">
    <li class="cursor-pointer upload-file error" (click)="selectFile()">
      <app-icon name="file"></app-icon>
      <div class="cursor-pointer info-file">
        <span class="visually-hidden">Errore caricamento file:</span>
        <p [itTooltip]="file?.name">{{file?.name}}</p>
        <span class="upload-file-weight">{{getSize()}}</span>
      </div>
      <button>
        <span class="visually-hidden">Elimina file caricato {{ file?.name }} </span>
        <app-icon name="close"></app-icon>
      </button>
    </li>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <li class="cursor-pointer upload-file success" (click)="selectFile()">
      <app-icon name="file"></app-icon>
      <div class="cursor-pointer info-file">
        <span class="visually-hidden">File caricato:</span>
        <p [itTooltip]="file?.name">{{file?.name}}</p>
        <span class="upload-file-weight">{{getSize()}}</span>
      </div>
    </li>
  </ng-container>

</ng-container>