import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Notification } from 'bootstrap-italia';
import { ToastService, TStateNotif } from '../../services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss']
})
export class ToastComponent implements OnInit, AfterViewInit {
  @ViewChild('notification') myNotification: ElementRef | undefined;
  myNotificationRef: Notification | undefined;
  message = '';
  state: TStateNotif = 'info';
  constructor(
    private _toastService: ToastService
  ) { }

  ngOnInit(): void {
    this._toastService.emitNotif$.subscribe(value => {
      this.message = value.message;
      this.state = value.state;
      this.myNotificationRef?.show(value.duration);
    });
  }

  ngAfterViewInit(): void {
    this.myNotificationRef = new Notification(this.myNotification?.nativeElement);
  }

}
