<div class="login-page">
  <div id="content" class="container">
    <div class="wrapper">

      <section class="container" style="max-width: 40em">
        <div class="d-flex justify-content-center">
          <div class="logo-img"><img alt="logo.png" src="/assets/images/logo.svg" /></div>
        </div>

        <h1 class="logo-title">
          <span>Log in</span>
        </h1>

        <form class="login" [formGroup]="formGroup" (ngSubmit)="signIn()">

          <div class="form-group">
            <label for="userName" [class.active]="!!userNameControl.value">
              username
            </label>

            <input type="text" name="userName" [ngClass]="getClassInputUserName()" id="userName" autocomplete="off"
              formControlName="userName">

            @if (userNameInvalid) {
              <div class="form-feedback just-validate-error-label position-absolute" id="userName-error">
                <app-control-messages [control]="userNameControl" />
              </div>
            }
            
          </div>

          <div class="form-group">
            <label for="password" [class.active]="!!passwordControl.value">
              Password
            </label>
            
            <input [type]="showPass ? 'text': 'password'" name="password" 
                  [ngClass]="getClassInputPassword()" id="password" formControlName="password" />
            
            <span class="password-icon bg-transparent" [class.right-30]="passwordInvalid || passwordControl.valid"
              aria-hidden="true">

              @if (showPass) {
                <app-icon name="password-invisible" size="sm" class="password-icon-invisible" (click)="showPass = !showPass" />
              } @else {
                <app-icon name="password-visible" size="sm" class="password-icon-visible" (click)="showPass = !showPass" />
              }
            
            </span>

            @if (passwordInvalid) {
              <div class="form-feedback just-validate-error-label position-absolute" id="password-error">
                <app-control-messages [control]="passwordControl" />
              </div>
            }
            
          </div>

          <div class="form-check">
            <label for="id_remember">Ricordami</label>
            <input id="id_remember" name="remember" type="checkbox" formControlName="rememberMe" (change)="rememberMeChange()" />
          </div>

          <div class="py-4 login_buttons">
            <button class="btn btn-primary" type="submit" [disabled]="isDisabledBtnLogin()">
              Log in
            </button>
          </div>
        </form>
      </section>
    </div>
  </div>
</div>

@if (showModalEnteList == true) {
  <app-modal-lista-ente (outSend)="selectEnte($event)" />
}