<table class="table">
  <thead>
    <tr>
      <th *ngFor="let item of header"> {{ item }} </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of body.filter">
      <td *ngFor="let i of item"> {{ i }} </td>
    </tr>
  </tbody>
</table>