import { IUtente, Utente } from "../utente";

export interface ISignInResponse extends IUtente {
  token: string;
}

export class SignInResponse extends Utente implements ISignInResponse {
  token: string;
  constructor(data?: any) {
    super(data);
    this.token = data?.token;
  }

}