<ng-container [ngSwitch]="file?.state">

  <ng-container *ngSwitchCase="'SUCCESS'">
    <li class=" cursor-pointer upload-file success" (click)="selectImg()">
      <div class="upload-image">
        <ng-container *ngIf="isPdf(file?.name)">
          <app-icon name="file-pdf-ext" size="lg" />
        </ng-container>
        <ng-container *ngIf="!isPdf(file?.name)">
          <img [src]="file?.data" [alt]="file.name" class="rounded">
        </ng-container>
      </div>
      <div class="cursor-pointer info-file">
        <span class="visually-hidden">Immagine caricata:</span>
        <p [itTooltip]="file?.name" [style.max-width]="maxWidth || null">{{file?.name}}</p>
        <span class="upload-file-weight">{{getSize()}}</span>
      </div>
      <button disabled>
        <span class="visually-hidden">Caricamento ultimato</span>
        <app-icon name="check"></app-icon>
      </button>
    </li>
  </ng-container>

  <ng-container *ngSwitchCase="'UPLOADING'">
    <li class=" cursor-pointer upload-file uploading" (click)="selectImg()">
      <div class="upload-image">
        <ng-container *ngIf="isPdf(file?.name)">
          <app-icon name="file-pdf-ext" size="lg" />
        </ng-container>
        <ng-container *ngIf="!isPdf(file?.name)">
          <img [src]="file?.data" [alt]="file.name" class="rounded">
        </ng-container>
      </div>
      <div class="cursor-pointer info-file">
        <span class="visually-hidden">Caricamento immagine:</span>
        <p [itTooltip]="file?.name" [style.max-width]="maxWidth || null">{{file?.name}}</p>
        <span class="upload-file-weight">{{getSize()}}</span>
      </div>
      <button>
        <span class="visually-hidden">Annulla caricamento {{ file?.name }}</span>
        <app-icon name="close" />
      </button>
      <div class="progress progress-image">
        <div class="progress-bar" role="progressbar" [ngStyle]="getProgress()" [attr.aria-valuenow]="progress || 0"
          aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </li>
  </ng-container>

  <ng-container *ngSwitchCase="'ERROR'">
    <li class=" cursor-pointer upload-file error" (click)="selectImg()">
      <div class="upload-image">
        <ng-container *ngIf="isPdf(file?.name)">
          <app-icon name="file-pdf-ext" size="lg" />
        </ng-container>
        <ng-container *ngIf="!isPdf(file?.name)">
          <img [src]="file?.data" [alt]="file.name" class="rounded">
        </ng-container>
      </div>
      <div class="cursor-pointer info-file">
        <span class="visually-hidden">Error caricamento immagine:</span>
        <p [itTooltip]="file?.name" [style.max-width]="maxWidth || null">{{file?.name}}</p>
        <span class="upload-file-weight">{{getSize()}}</span>
      </div>
      <button [itTooltip]="file?.msg || ''">
        <span class="visually-hidden">Elimina immagine caricata {{ file?.name }} </span>
        <app-icon name="close"></app-icon>
      </button>
    </li>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <li class=" cursor-pointer upload-file success" (click)="selectImg()">
      <div class="upload-image">
        <ng-container *ngIf="isPdf(file?.name)">
          <app-icon name="file-pdf-ext" size="lg" />
        </ng-container>
        <ng-container *ngIf="!isPdf(file?.name)">
          <img [src]="file?.data" [alt]="file.name" class="rounded">
        </ng-container>
      </div>
      <div class="cursor-pointer info-file">
        <span class="visually-hidden">Immagine caricata:</span>
        <p [itTooltip]="file?.name" [style.max-width]="maxWidth || null">{{file?.name}}</p>
        <span class="upload-file-weight">{{getSize()}}</span>
      </div>
    </li>
  </ng-container>

</ng-container>