export interface IEnte {
    enteId: number;
    denominazione: string;
    provincia: string;
    sigliaProvincia: string;
    codiceCatastale: string;
    posizioneGeografica: string;
    regione: string;
}

export class Ente implements IEnte {
    enteId: number;
    denominazione: string;
    provincia: string;
    sigliaProvincia: string;
    codiceCatastale: string;
    posizioneGeografica: string;
    regione: string;
    constructor(data?: any) {
        this.enteId = data?.enteId;
        this.denominazione = data?.denominazione;
        this.provincia = data?.provincia;
        this.sigliaProvincia = data?.sigliaProvincia;
        this.codiceCatastale = data?.codiceCatastale;
        this.posizioneGeografica = data?.posizioneGeografica;
        this.regione = data?.regione;
    }
}