<div class="it-example-modal">
  <div class="modal" tabindex="-1" role="dialog" id="modalPreviewFullScreen" data-bs-backdrop="static"
    aria-labelledby="modalPreviewFullScreen" aria-describedby="modal1Description" #modalPreviewFullScreen>
    <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Chiudi finestra modale"
      itTooltip="Chiudi finestra modale" title="Chiudi finestra modale">
      <app-icon name="close" size="lg"></app-icon>
    </button>

    <div class="modal-dialog modal-fullscreen" role="document">
      <div class="modal-content">
        <img class="img-fluid" [src]="pathImg" alt="">
      </div>
    </div>

  </div>
</div>