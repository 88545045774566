import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { SharedConstants } from 'src/app/shared/shared.constants';

export type TStateNotif = 'info' | 'success' | 'error' | 'warning';
@Injectable({
    providedIn: 'root'
})
export class ToastService {
    public emitNotif$ = new Subject<{ message: string, duration?: number, state: TStateNotif }>();

    constructor() { }

    public info(config: { message: string, duration?: number }) {
        if (!config.duration) {
            config.duration = 2000;
        }
        this.emitNotif$.next({ ...config, state: 'info' });
    }

    public success(config: { message: string, duration?: number }) {
        if (!config.duration) {
            config.duration = 2000;
        }
        this.emitNotif$.next({ ...config, state: 'success' });
    }

    public warning(config: { message: string, duration?: number }) {
        if (!config.duration) {
            config.duration = 2000;
        }
        this.emitNotif$.next({ ...config, state: 'warning' });
    }

    public error(config: { message: string, duration?: number }) {
        if (!config.duration) {
            config.duration = 2000;
        }
        config.message = config.message || SharedConstants.MSG.ERROR.NOT_FOUND;
        this.emitNotif$.next({ ...config, state: 'error' });
    }
}