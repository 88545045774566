import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Modal } from 'bootstrap-italia';
import { Subscription } from 'rxjs';
import { IEnte } from 'src/app/models/ente';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-modal-lista-ente',
  templateUrl: './modal-lista-ente.component.html',
  styleUrls: ['./modal-lista-ente.component.scss']
})
export class ModalListaEnteComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('modalListEnte') modal: ElementRef | undefined;
  handlerModal: Modal | undefined;

  @Output() outClose = new EventEmitter();
  @Output() outSend = new EventEmitter<IEnte>();

  enti: IEnte[] = [];
  suscription = new Subscription();

  constructor(
    private _authService: AuthService
  ) { }

  ngOnInit(): void {
    this.suscription = this._authService.utente$.subscribe(utente => {
      this.enti = utente?.enti || [];
    });
  }

  ngAfterViewInit(): void {
    this.handlerModal = new Modal(this.modal?.nativeElement);
    this.handlerModal?.show();
    this.modal?.nativeElement.addEventListener('hidden.bs.modal', () => this.closeModal());
  }

  ngOnDestroy(): void {
    this.handlerModal?.hide();
    this.suscription.unsubscribe();
  }

  closeModal() {
    this.handlerModal?.hide();
    this.outClose.emit(true);
  }

  selectEnte(ente: IEnte): void {
    this._authService.enabledLoaderAuth = true;
    this.handlerModal?.hide();
    this.outSend.emit(ente);
  }

}
