<ng-container *ngIf="start">
  
  <ng-container [ngSwitch]="typeInput">

    <ng-container *ngSwitchCase="'input'">
      <span class="position-input">
        <div class="progress-spinner progress-spinner-active"></div>
      </span>
    </ng-container>

    <ng-container *ngSwitchCase="'select'">
      <span class="position-select">
        <div class="progress-spinner progress-spinner-active"></div>
      </span>
    </ng-container>

  </ng-container>

</ng-container>