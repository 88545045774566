import { IPermesso, Permesso } from "./permesso";

export interface IRuolo {
    ruoloId: number;
    descrizione: string;
    permessi: IPermesso[];
}

export class Ruolo implements IRuolo {
    ruoloId: number;
    descrizione: string;
    permessi: IPermesso[];
    constructor(data?: any) {
        this.ruoloId = data?.ruoloId;
        this.descrizione = data?.descrizione;
        this.permessi = (data?.permessi || []).map((row: any) => new Permesso(row));
    }
}