import { Component, HostListener } from '@angular/core';
import { AuthService } from './services/auth.service';
import { Globals } from './core/services/globals.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {
  title = 'app';
  constructor(
    public authService: AuthService,
    private _globals: Globals
  ) {
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this._globals.__SCREEN_HEIGHT = window.innerWidth;
  }
}
