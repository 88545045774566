import { AfterViewInit, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { SharedConstants } from '../../shared.constants';
import { IconColor, IconName, IconSize } from './icon';

@Component({
  selector: 'app-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss']
})
export class AppIconComponent implements AfterViewInit {

  /**
  * The icon name
  */
  @Input() name!: IconName;

  /**
   * The icon size
   */
  @Input() size?: IconSize;

  /**
   * The icon color
   */
  @Input() color?: IconColor;

  /**
   * Create a padding proportional to the size of the surrounding icon.
   */
  @Input() padded?: boolean | 'true' | 'false';

  /**
   * Custom class
   */
  @Input() class: string = ''

  

  /**
   * Return the icon href
   */
  get iconHref(): string {
    return `/bootstrap-italia/dist/svg/sprites.svg#it-${this.name}`;
  }

  get isIconCustom(): boolean {
    return SharedConstants.ICONS_CUSTOM.includes(this.name);
  }

  get classIconCustom(): string {
    return `bi-${this.name}`;
  }

  /**
   * Return the icon class
   */
  get iconClass(): string {
    let iconClass = 'icon';
    if (this.size) {
      iconClass += ` icon-${this.size}`
    }
    if (this.color) {
      iconClass += ` icon-${this.color}`
    }
    if (this.class) {
      iconClass += ` ${this.class}`
    }
    return iconClass;
  }

  get iconCustomClass(): string {
    let iconClass = `bi ${this.classIconCustom} icon-custom`;
    if (this.size) {
      iconClass += ` icon-custom-${this.size}`
    }
    if (this.color) {
      iconClass += ` -${this.color}-color`;
    }
    if (this.class) {
      iconClass += ` ${this.class}`
    }
    return iconClass;
  }

  constructor(
    protected readonly _renderer: Renderer2,
    protected readonly _elementRef: ElementRef
  ) {
  }

  ngAfterViewInit() {
    this._renderer.removeAttribute(this._elementRef.nativeElement, 'class');
  }

}
