<div class="container">
  <img class="img-fluid d-block mx-auto my-5"
    src="https://bandaultralarga.italia.it/wp-content/themes/accupress/assets/media/404.svg" alt="404">
  <h1 class="h4 text-center">Pagina non trovata</h1>
  <p class="text-center text-secondary">Verificare che la URL inserita sia corretta.</p>
  <div class="d-flex justify-content-center">
    <a class="btn text-center" [routerLink]="['/ricerca-documenti']"
      title="Vai alla pagina: Home">
      TORNA ALLA HOME
    </a>
  </div>
</div>