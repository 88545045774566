import { Ente, IEnte } from "../ente";
import { ISignInResponse, SignInResponse } from "./SignInResponse";

export interface IRefreshTokenResponse extends ISignInResponse {
    enteSelected: IEnte;
}

export class RefreshTokenResponse extends SignInResponse implements IRefreshTokenResponse {
    enteSelected: IEnte;
    constructor(data?: any) {
        super(data);
        this.enteSelected = new Ente(data?.enteSelected);
    }
    
}