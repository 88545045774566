import { Ente, IEnte } from "./ente";
import { IRuolo, Ruolo } from "./ruolo";

export interface IUtente {
    utenteId?: number;
    nome: string;
    cognome: string;
    email?: string;
    userName: string;
    ruolo: IRuolo;
    enti: IEnte[];
    readonly?: boolean;
}

export class Utente implements IUtente {
    utenteId?: number | undefined;
    nome: string;
    cognome: string;
    email: string;
    userName: string;
    ruolo: IRuolo;
    enti: IEnte[];
    readonly?: boolean;
    constructor(data?: any) {
        this.utenteId = data?.utenteId;
        this.nome = data?.nome;
        this.cognome = data?.cognome;
        this.email = data?.email;
        this.userName = data?.userName;
        this.ruolo = data?.ruoloId;
        this.enti = (data?.enti || []).map((row: any) => new Ente(row));
        this.ruolo = new Ruolo(data?.ruolo);
        this.readonly = data?.readonly || false;
    }
}