<div class="row position-relative">
  <div class="col">
    <nav class="pagination-wrapper justify-content-center" aria-label="Esempio di navigazione della pagina">
      <ul class="pagination">
        <li class="page-item">
          <a class="page-link cursor-pointer" (click)="previousPage()">
            <app-icon name="chevron-left" />
            <!-- <span class="sr-only">Pagina precedente</span> -->
          </a>
        </li>

        <li class="page-item" *ngFor="let elem of pages">

          <a class="page-link cursor-pointer" aria-current="page"
            *ngIf="!elem.separator && elem.page === currentPagination.currentPage" (click)="setPage(elem.page)">
            {{ elem.page }}
          </a>

          <a class="page-link cursor-pointer" (click)="setPage(elem.page)"
            *ngIf="!elem.separator && elem.page !== currentPagination.currentPage;">{{ elem.page }}</a>

          <span class="page-link" *ngIf="elem.separator">...</span>

        </li>

        <li class="page-item">
          <a class="page-link cursor-pointer" (click)="nextPage()">
            <!-- <span class="sr-only">Pagina successiva</span> -->
            <app-icon name="chevron-right" />
          </a>
        </li>
      </ul>

      <div class="dropdown dropdown-position-end">
        <button class="btn btn-dropdown dropdown-toggle primary-color" role="button" id="pagerChanger"
          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" aria-label="Salta alla pagina"
          (click)="openMenu()" #pageChanger>
          <span>{{ currentPagination.pageSize }} elementi per pagina</span>
          <app-icon name="expand" size="sm" class="icon-primary" *ngIf="!dropDownOpen" />
          <app-icon name="expand" size="sm" class="icon-primary rotate-180" *ngIf="dropDownOpen" />
        </button>
        <div class="dropdown-menu" aria-labelledby="pagerChanger">
          <div class="link-list-wrapper">
            <ul class="link-list">
              <li *ngFor="let iter of [10,20,30,40,50]">
                <a class="dropdown-item list-item cursor-pointer" (click)="changePageSize(iter)">
                  <span>{{iter}} elementi per pagina</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </nav>
  </div>
</div>