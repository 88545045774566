import { AbstractControl, ValidatorFn } from '@angular/forms';

export class ValidatorsExtend {
    static getValidatorErrorMessage(validatorName: string, validatorValue?: any): string {
        const config: any = {
            required: 'Campo obbligatorio.',
            minlength: `Lunghezza minima (${validatorValue.requiredLength} caratteri).`,
            maxlength: `Supera la lunghezza massima (${validatorValue.requiredLength} caratteri).`,
            requireobject: '* È necessario selezionare un elemento.',
            number: 'Il campo accetta numeri e/o simboli validi.',
            isNumeric: 'Il campo accetta solo numeri.',
            email: 'Formato mail non valido.',
            isEmail: 'Formato mail non valido.',
            isInvalidDate: 'Formato della data non corretto.',
            isInvalidHour: `Formato dell'ora non corretto.`,
            isPhoneNumber: 'Il numero inserito non è valido.',
            isInvalidCharacter: `Caratteri non validi`,
            isURL: `L'URL della pagina inserita non è corretto.`,
            max: `Supera il valore massimo a ${validatorValue.max}`,
            min: `Il valore minimo è ${validatorValue.min}`,
            matDatepickerParse: 'Data non valida',
            requireMatch: 'Opzione non valida',
            isInvalidMaxDate: "Data non è valida",
            NoPassswordMatch: "Le password non corrispondono. Riprova"
        };
        return config[validatorName];
    }

    static passwordMatchValidator(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            const password: string = control.get('password')?.value;
            const passwordConfirm: string = control.get('passwordConfirm')?.value;

            // compare is the password math
            if (password !== passwordConfirm) {
                // if they don't match, set an error in our confirmPassword form control
                control.get('passwordConfirm')?.setErrors({ NoPassswordMatch: true });
            }

            return null;
        };
    }

    static isAlphanumeric(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (isEmptyInputValue(control.value)) {
                return null;  // don't validate empty values to allow optional controls
            }
            const regex = new RegExp('^[0-9a-zA-Z]+$');
            const value: string = control.value;
            return regex.test(value) ?
                null : { isMultiAlphanumeric: { requiredPattern: 'solo permite letras y números', actualValue: value } };
        };
    }

    static isNumeric(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (isEmptyInputValue(control.value)) {
                return null;  // don't validate empty values to allow optional controls
            }
            const regex = new RegExp('^([0-9])*$');
            const value: string = control.value;
            return regex.test(value) ?
                null : { isNumeric: { requiredPattern: 'only allow numbers', actualValue: value } };
        };
    }

    static requireObject(control: AbstractControl): { [key: string]: boolean } | null {
        return !control.value || typeof control.value === 'object' ?
            null : { requireobject: true };
    }

    static isValidDate(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }
            const unixTimeZero = Date.parse(control.value);
            const javaScriptRelease = Date.parse(control.value);
            if (unixTimeZero > 0 && javaScriptRelease > 0) {
                return null;
            } else {
                return { isInvalidDate: true };
            }
        };
    }

    static isValidRangeDate(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }
            const firstDate = control.value[0];
            const secondDate = control.value[1];
            const unixTimeZeroFirst = Date.parse(firstDate);
            const javaScriptReleaseFirst = Date.parse(firstDate);
            const unixTimeZeroSecond = Date.parse(secondDate);
            const javaScriptReleaseSecond = Date.parse(secondDate);
            if (unixTimeZeroFirst > 0 && javaScriptReleaseFirst > 0 && unixTimeZeroSecond && javaScriptReleaseSecond > 0) {
                return null;
            } else {
                return { isInvalidDate: true };
            }
        };
    }

    static isValidHour(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            if (isEmptyInputValue(control.value)) {
                return null;
            }
            const regex = new RegExp('^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$');
            const value: string = control.value;
            const valid = regex.test(value) ?
                null : { isInvalidHour: true };
            return valid;
        };
    }

    /**
     * Permite validar correo electronico.
     */
    static isOptionalEmail(control: AbstractControl): { [key: string]: any; } | null {
        const value: string = control.value;
        // tslint:disable-next-line: max-line-length
        const regex = /[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?/g;
        if (isEmptyInputValue(value)) {
            return null;  // don't validate empty values to allow optional controls
        }
        return regex.test(value) ?
            null : { isEmail: 'Formato de correo no es válido.' };
    }
    /**
     * Permite validar solo url www.angular.io
     */
    static isURL(control: AbstractControl): { [key: string]: any; } | null {
        const regex = /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi;
        const value: string = control.value;
        if (isEmptyInputValue(value)) {
            return null;  // don't validate empty values to allow optional controls
        }
        return regex.test(value) ? null : { isURL: 'the URL is incorrect' };
    }

    /**
     * Permitir que ingrese letras y números sin caracteres especiales
     */
    static isInvalidCharacter(control: AbstractControl): { [key: string]: any; } | null {
        const regex = /^[ a-zA-Z0-9_áéíóúàèìòùÀÈÌÒÙñÁÉÍÓÚÑÜü\'.\s]*$/;
        const value: string = control.value;
        if (isEmptyInputValue(value)) {
            return null;  // don't validate empty values to allow optional controls
        }
        return regex.test(value) ? null : { isInvalidCharacter: true };
    }

    static requireMatch(control: AbstractControl) {
        const selection: any = control.value;
        if (typeof selection === 'string' && !selection.includes('_SELECT_')) {
            return { requireMatch: true };
        }
        return null;
    }

    static maxDateOfBirth(): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            let dateValue = new Date(control.value);
            if (dateValue > new Date()) return { isInvalidMaxDate: true };
            return null;
        };
    }
}

function isEmptyInputValue(value: any) {
    return value == null || typeof value === 'string' && value.length === 0;
}
