import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-spinner-input-form',
  templateUrl: './spinner-input-form.component.html',
  styleUrls: ['./spinner-input-form.component.scss']
})
export class SpinnerInputFormComponent implements OnInit {
  @Input() start = true;
  @Input() typeInput: 'input' | 'select' = 'input'; 
  constructor() { }

  ngOnInit(): void {
  }

}
