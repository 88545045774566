import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { fakeBackendProvider } from './core/fake-backend.interceptor';
import { RequestInterceptorService } from './core/services/request.service';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { GlobalErrorHandler } from './core/global-error-handler.service';

const REQUEST_INTERCEPTOR = { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptorService, multi: true };

@NgModule({
  declarations: [
    AppComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    FormsModule,
  ],
  providers: [
    // fakeBackendProvider,
    REQUEST_INTERCEPTOR,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
