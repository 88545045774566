import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Modal } from 'bootstrap-italia';

@Component({
  selector: 'app-modal-preview-fullscreen',
  templateUrl: './modal-preview-fullscreen.component.html',
  styleUrls: ['./modal-preview-fullscreen.component.scss']
})
export class ModalPreviewFullscreenComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() pathImg: string = '';
  @ViewChild('modalPreviewFullScreen') modal: ElementRef | undefined;
  handlerModal: Modal | undefined;
  @Output() outClose = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.handlerModal = new Modal(this.modal?.nativeElement);
    this.handlerModal?.show();
    this.modal?.nativeElement.addEventListener('hidden.bs.modal', () => {
      this.closeModal();
    });
  }

  ngOnDestroy(): void {
    this.handlerModal?.hide();
  }

  closeModal() {
    this.handlerModal?.hide();
    this.outClose.emit(true);
  }
}
