export interface IPagination {
    pageCount: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
    currentPage: number;
}

export class Pagination implements IPagination {
    pageCount: number;
    pageSize: number;
    totalCount: number;
    totalPages: number;
    currentPage: number;
    constructor(data?: any) {
        this.pageCount = data?.pageCount;
        this.pageSize = data?.pageSize ;
        this.totalCount = data?.totalCount;
        this.totalPages = data?.totalPages ;
        this.currentPage = data?.currentPage;
    }

}   