export function onlyOneEspace(str: any, spaces: string = ' '): string {
    if (!str) return str;
    str = String(str);
    // Quitando los uno o varios espacios
    str = str.replace(/\s+/g, spaces);
    // Quitando los saltos de linea
    str = str.replace(/\n+/g, spaces);
    str = str.trim();
    return str;
}

export function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if (d > 0) {//Use timestamp until depleted
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

export function isNumber(value: string) {
    const regex = new RegExp('^([0-9])*$');
    return regex.test(value || '');
}

export function toStringDate(date: Date | undefined, format?: '/' | '-'): string | undefined {
    if (!date) return undefined;

    let _date: any = typeof (date) === 'string' ? new Date(date) : date;

    if (_date) date = _date.setHours(4);

    _date = _date?.toISOString().split('T')[0];

    if (format) {
        const [year, month, day] = _date.split('-');
        if (format === '/') _date = `${day}/${month}/${year}`;
    }

    return _date;
}

export function toStringDateUTC(date: Date | undefined): string | undefined {
    if (!date) return undefined;
    if (typeof (date) === 'string') return new Date(new Date(date).toString().split('GMT')[0] + ' UTC').toISOString();
    return new Date(date.toString().split('GMT')[0] + ' UTC').toISOString();
}
