import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Modal } from 'bootstrap-italia';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.scss']
})
export class ModalConfirmComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('modalConfirm') modal: ElementRef | undefined;
  handlerModal: Modal | undefined;

  @Output() outClose = new EventEmitter();
  @Output() outConfirm = new EventEmitter();

  @Input() title = '';
  @Input() descrizione = '';
  @Input() alertDesc = false;
  @Input() textBtnConfirm = 'Si';
  @Input() textBtnCancel = 'No';
  @Input() backdrop: 'static' | boolean = true;
  constructor() { }

  ngOnInit(): void { }

  ngAfterViewInit(): void {
    this.handlerModal = new Modal(this.modal?.nativeElement, { backdrop: this.backdrop });
    this.handlerModal?.show();
    this.modal?.nativeElement.addEventListener('hidden.bs.modal', () => this.closeModal());
  }

  ngOnDestroy(): void {
    this.handlerModal?.hide();
  }

  closeModal() {
    this.handlerModal?.hide();
    this.outClose.emit(true);
  }

  sendConfirm(value: boolean): void {
    this.outConfirm.emit(value);
    this.handlerModal?.hide();
  }
}
