import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '../shared/shared.module';
import { ModalListaEnteComponent } from './components/modal-lista-ente/modal-lista-ente.component';
import { ToastComponent } from './components/toast/toast.component';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  declarations: [
    LoginComponent,
    ModalListaEnteComponent,
    ToastComponent,
    LoaderComponent
  ],
  exports: [
    LoginComponent,
    ToastComponent,
    LoaderComponent
  ]
})
export class CoreModule { }
