import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-document-item',
  templateUrl: './document-item.component.html',
  styleUrls: ['./document-item.component.scss']
})
export class DocumentItemComponent implements OnInit {
  @Input() file: any;
  @Input() progress: number = 0;
  @Output() outSelect = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

  selectFile(): void {
    this.outSelect.emit(this.file);
  }

  getSize(): string {
    if (!this.file?.size) return '';

    let textSize = '';
    const sizeKB = this.file?.size / 1024;

    if (this.file?.size < (1 * 1024 * 1024)) {
      textSize = `${(sizeKB).toFixed(2)} KB`;
    } else {
      textSize = `${(sizeKB / 1024).toFixed(2)} MB`;
    }

    return textSize;
  }

  getProgress(): any {
    return {
      'width': `${this.progress}%`
    }
  }

}
