import { Directive, ElementRef, HostListener } from '@angular/core';
import { CommonService } from 'src/app/core/services/common.service';

@Directive({
    selector: '[onlyNumber]'
})
export class CsOnlyNumberDirective {
    constructor(
        private _el: ElementRef,
        private _commonSrv: CommonService
    ) {
        // _el.nativeElement.style.backgroundColor = 'yellow';
    }

    @HostListener('keydown', ['$event']) onKeyDown(evt: any) {
        try {
            this._commonSrv.validateOnlyNumber(evt)
        } catch (error) {
        }

    }
}
