import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription, timer } from "rxjs";
import { tap } from "rxjs";
import { map, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { HeadersService } from "../core/services/headers.service";
import { LocalStorageService } from "../core/services/localstorage.service";
import { ISignInResponse, SignInResponse } from "../models/auth/SignInResponse";
import { IRefreshTokenResponse, RefreshTokenResponse } from "../models/auth/refreshTokenReponse";
import { IEnte } from "../models/ente";
import { IUtente, Utente } from "../models/utente";
import { Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _header: HttpHeaders;
  enteSelected$ = new BehaviorSubject<IEnte | null>(null);
  utente$ = new BehaviorSubject<IUtente | null>(null);
  enabledLoaderAuth = false;
  intervalRefreshTokenSub = new Subscription();

  constructor(
    private _http: HttpClient,
    private _headerService: HeadersService,
    private _localStorageService: LocalStorageService,
    private _router: Router
  ) {
    this._header = this._headerService.buildHeader();
  }

  signIn(userName: string, password: string): Observable<ISignInResponse> {
    const body = { userName, password };
    const headers = this._header;
    return this._http.post<ISignInResponse>(`${environment.host}/utente/sign-in`, body, { headers })
      .pipe(
        map(data => new SignInResponse(data)),
        tap(data => this.utente$.next(new Utente(data))),
        tap(data => this.initIntervalRefreshToken(data?.token))
      );
  }

  refreshToken(enabledLoaderAuth = true): Observable<IRefreshTokenResponse> {
    const codiceCatastale = this.enteSelected$.value?.codiceCatastale;
    const headers = this._header;
    this.enabledLoaderAuth = enabledLoaderAuth;
    return this._http.post<IRefreshTokenResponse>(`${environment.host}/utente/refresh-token`, { codiceCatastale }, { headers })
      .pipe(
        map(data => new RefreshTokenResponse(data)),
        tap(data => {
          this._localStorageService.set('accessToken', data.token);
          this.enteSelected$.next(data.enteSelected);
          this.utente$.next(new Utente(data));
        }),
        tap(data => this.initIntervalRefreshToken(data?.token))
      );
  }

  isPermissionActive(path: string): boolean {
    const permesso = (this.utente$.value?.ruolo?.permessi || []).find(f => f.path === path);

    if (!permesso) { this._router.navigate(['/']); return false; }

    return true;
  }

  decryptTokenPayload(token: string): any {
    if (!token) return {};
    return JSON.parse(atob(token.split('.')[1]));
  }

  initIntervalRefreshToken(token?: string): void {
    if (!token) return;
    const tokenDecrypt = this.decryptTokenPayload(token);

    if (!tokenDecrypt?.exp || !tokenDecrypt?.iat) return;

    const timeSeconds = (tokenDecrypt?.exp - tokenDecrypt?.iat);

    this.intervalRefreshTokenSub.unsubscribe();

    const period = ((timeSeconds * 1000) - 5000);

    if (period <= 0) return;

    this.intervalRefreshTokenSub = timer(period).subscribe(() => {
      this.refreshToken(false).subscribe();
    });
  }
}