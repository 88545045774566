<div class="it-example-modal">
  <div class="modal" tabindex="-1" role="dialog" id="modal2" aria-labelledby="modal2Title" #modal>
     <div class="modal-dialog" role="document">
        <div class="modal-content">
           <div class="modal-header">
              <h2 class="modal-title h5 " id="modal2Title">Questo è un messaggio di notifica</h2>
              <button class="btn-close" type="button" data-bs-dismiss="modal" aria-label="Chiudi finestra modale">
                 <svg class="icon">
                    <use href="/bootstrap-italia/dist/svg/sprites.svg#it-close"></use>
                 </svg>
              </button>
           </div>
           <div class="modal-body">
              <p>In questo caso vengono forniti un pulsante di conferma e uno di chiusura della modale.</p>
           </div>
           <div class="modal-footer">
              <button class="btn btn-primary btn-sm" type="button">Ok</button>
           </div>
        </div>
     </div>
  </div>
</div>