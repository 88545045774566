export interface IPermesso {
    permessoId: number;
    descrizione: string;
    path: string;
    ordine: number;
}

export class Permesso implements IPermesso {
    permessoId: number;
    descrizione: string;
    path: string;
    ordine: number;
    constructor(data?: any) {
        this.permessoId = data?.permessoId;
        this.descrizione = data?.descrizione;
        this.path = data?.path;
        this.ordine = data?.ordine;
    }

}