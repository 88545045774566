import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { IEnte } from 'src/app/models/ente';
import { AuthService } from 'src/app/services/auth.service';
import { onlyOneEspace } from '../../helpers/util.helper';
import { LocalStorageService } from '../../services/localstorage.service';
import { ToastService } from '../../services/toast.service';

interface IFormValue {
  userName: string;
  password: string;
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  formGroup: FormGroup;
  showPass = false;
  serviceLogin = false;
  msgNotify = '';
  showModalEnteList = false;

  constructor(
    private _fb: FormBuilder,
    private _router: Router,
    private _authService: AuthService,
    private _localStorageService: LocalStorageService,
    private _toastService: ToastService
  ) {
    document.title = 'Login';
    this.formGroup = this._buildForm();
  }

  get userNameControl(): FormControl { return this.formGroup.controls['userName'] as FormControl; }
  get passwordControl(): FormControl { return this.formGroup.controls['password'] as FormControl; }
  get rememberMeControl(): FormControl { return this.formGroup.controls['rememberMe'] as FormControl; }

  get userNameInvalid(): boolean {
    return this.userNameControl.invalid && !this.userNameControl.pristine;
  }

  get passwordInvalid(): boolean {
    return this.passwordControl.invalid && !this.passwordControl.pristine;
  }

  private _buildForm(): FormGroup {

    const validatorsForm = (maxLength = 0) => {
      return [Validators.required, Validators.minLength(4), Validators.maxLength(maxLength)]
    }

    return this._fb.group({
      userName: [this._localStorageService.get('userName') || null, validatorsForm(50)],
      password: [null, validatorsForm(20)],
      rememberMe: [this._localStorageService.get('userName') ? true : false]
    });

  }

  signIn(): void {

    let { userName, password } = this.formGroup.value as IFormValue;

    userName = onlyOneEspace(userName || '', '');
    password = onlyOneEspace(password || '', '');

    this.userNameControl.setValue(userName);
    this.passwordControl.setValue(password);

    if (this.formGroup.invalid || this.serviceLogin || this.formGroup.pristine) return;

    this.serviceLogin = true;
    this._authService.enabledLoaderAuth = true;

    userName = userName.toLowerCase();

    this._authService.signIn(userName, password).subscribe({
      next: (utente) => {
        this._localStorageService.set('accessToken', utente.token);

        this.serviceLogin = false;

        if (utente.enti.length == 1) return this.selectEnte(utente.enti[0]);

        this._authService.enabledLoaderAuth = false;
        this.showModalEnteList = true;
      },
      error: (err) => {
        this.serviceLogin = false;
        this._authService.enabledLoaderAuth = false;
        this.formGroup.setErrors({ error: true });
        this._toastService.error({ message: err.error?.msg });
      }
    });
  }

  selectEnte(ente: IEnte): void {
    this._authService.enteSelected$.next(ente);
    this._router.navigate(['ricerca-documenti']);
  }

  rememberMeChange(): void {
    if (this.rememberMeControl.value) {
      this._localStorageService.set('userName', this.userNameControl.value);
    } else {
      this._localStorageService.remove('userName');
    }
  }

  setFocusInit(): void {
    if (this.userNameControl.value) {
      const input: any = document.querySelector('input[name="password"]');
      input?.focus();
      input?.click();
    } else {
      const input: any = document.querySelector('input[name="userName"]');
      input?.focus();
      input?.click();
    }
  }

  getClassInputUserName() {
    return {
      'form-control': true,
      "text-lowercase": true,
      "is-invalid": this.userNameInvalid,
      "is-valid": this.userNameControl.valid
    };
  }

  getClassInputPassword() {
    return {
      "form-control": true,
      "input-password": true,
      "is-invalid": this.passwordInvalid,
      "is-valid": this.passwordControl.valid
    };
  }

  isDisabledBtnLogin() {
    return this.formGroup.invalid ||  this.serviceLogin || this.formGroup.pristine;
  }

}
