<div class="notification with-icon custom-position-notification" [ngClass]="state" role="alert"
  aria-labelledby="notification-title" id="notification" #notification>
  <h2 id="notification-title" class="h5 ">

    <ng-container [ngSwitch]="state">
      <ng-container *ngSwitchCase="'info'"> <app-icon name="info-circle" /></ng-container>
      <ng-container *ngSwitchCase="'error'"> <app-icon name="close-circle" /></ng-container>
      <ng-container *ngSwitchCase="'warning'"><app-icon name="error" /></ng-container>
      <ng-container *ngSwitchCase="'success'"><app-icon name="check-circle" /></ng-container>
    </ng-container>

    <span>{{message}}</span>
  </h2>
</div>