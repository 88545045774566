import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from './tooltip.directive';
import { CsOnlyNumberDirective } from './cs-only-number.directive';
import { CsNumberDirective } from './cs-number.directive';
import { FocusDirective } from './focus.directive';
import { HighlightDirective } from './highlight.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [	
    TooltipDirective,
    CsOnlyNumberDirective,
    CsNumberDirective,
    FocusDirective,
      HighlightDirective
   ],
  exports: [
    TooltipDirective,
    CsOnlyNumberDirective,
    CsNumberDirective,
    FocusDirective
  ]
})
export class DirectivesModule { }
