import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import { LocalStorageService } from '../services/localstorage.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard  {
  constructor(
    private _router: Router,
    private _localStorageService: LocalStorageService,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    let value = true;
    const existToken = (this._localStorageService.get('accessToken') || '').length > 0;
    const isRouteLogin = (state.url || '').includes('login');

    if (isRouteLogin) {
      if (existToken) this._router.navigate(['/']);
    } else {
      if (!existToken) this._router.navigate(['/login']);
    }

    return value;
  }
}