import { Component, Input } from '@angular/core';

@Component({
    selector    : 'app-curtain',
    templateUrl : 'curtain.component.html',
    styleUrls   : ['curtain.component.scss']
})
export class CurtainComponent {
    @Input() type?: string;
    constructor() {

    }
}