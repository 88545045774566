<div class="it-example-modal">
  <div class="modal" tabindex="-1" role="dialog" id="modalListEnte" data-bs-backdrop="static" #modalListEnte>
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body wrapper-card">

          <div class="card card-teaser rounded shadow cursor-pointer" *ngFor="let ente of enti" (click)="selectEnte(ente)">
            <div class="card-body">
              <h3 class="card-title h5 ">
                <app-icon name="pa"></app-icon>
                <span>{{ente.denominazione}}</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>