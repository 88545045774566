<div class="it-example-modal">
  <div class="modal" tabindex="-1" role="dialog" id="modalConfirm" aria-labelledby="modalConfirmTitle" #modalConfirm
    aria-describedby="modalConfirmDescription">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title h5 no_toc" id="modalConfirmTitle">{{title}}</h2>
        </div>
        <div class="modal-body">
          <p><strong *ngIf="alertDesc">Attenzione: </strong><span>{{descrizione}}</span></p>
        </div>
        <div class="modal-footer">
          <button class="btn btn-outline-primary btn-sm" type="button" (click)="sendConfirm(false)">
            {{ textBtnCancel }}
          </button>
          <button class="btn btn-primary btn-sm" type="button" (click)="sendConfirm(true)">
            {{ textBtnConfirm }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>