export class SharedConstants {
    static get PATH_TEMPLATE() {
        return {
            FOGLIO_FAMIGLIA: 'assets/template/TEMPLATE_FOGLIO_FAMIGLIA.xlsm',
            SCHEDA_INDIVIDUALE: 'assets/template/TEMPLATE_SCHEDA_INDIVIDUALE.xlsm',
            CARTELLINO_IDENTITA: 'assets/template/TEMPLATE_CARTELLINO_IDENTITA.xlsm',
            SCHEDA_INDIVIDUALI_ELIMINATE: 'assets/template/TEMPLATE_SCHEDA_INDIVIDUALE.xlsm',
            ATTO_MATRIMONIO: 'assets/template/TEMPLATE_ATTO_MATRIMONIO.xlsm',
            ATTO_NASCITA: 'assets/template/TEMPLATE_ATTO_NASCITA.xlsm',
            ATTO_CITTADINANZA: 'assets/template/TEMPLATE_ATTO_CITADINANZA.xlsm',
            ATTO_MORTE: 'assets/template/TEMPLATE_ATTO_MORTE.xlsm'
        }
    }

    static get HEADER_TEMPLATE_KEY() {
        return {
            FOGLIO_FAMIGLIA: ['NOME', 'COGNOME', 'LUOGO NASCITA', 'DATA NASCITA', 'STATO DI NASCITA', 'CONIUGE', 'FIGLIO/A DI (PADRE)', 'FIGLIO/A DI (MADRE)', 'IMMAGINE'],
            SCHEDA_INDIVIDUALE: ['NOME', 'COGNOME', 'LUOGO NASCITA', 'DATA NASCITA', 'STATO DI NASCITA', 'CONIUGE', 'FIGLIO/A DI (PADRE)', 'FIGLIO/A DI (MADRE)', 'IMMAGINE'],
            CARTELLINO_IDENTITA: ['NUM. CARTA IDENTITA', 'NOME', 'COGNOME', 'DATA NASCITA', 'LUOGO NASCITA', 'DATA DI EMISSIONE', 'DATA DI SCADENZA', 'IMMAGINE'],
            SCHEDA_INDIVIDUALI_ELIMINATE: ['NOME', 'COGNOME', 'LUOGO NASCITA', 'DATA NASCITA', 'STATO DI NASCITA', 'CONIUGE', 'FIGLIO/A DI (PADRE)', 'FIGLIO/A DI (MADRE)', 'IMMAGINE'],
            ATTO_MATRIMONIO: ['NOME MARITO', 'COGNOME MARITO', 'LUOGO NASCITA MARITO', 'DATA NASCITA MARITO', 'NOME MOGLIE', 'COGNOME MOGLIE', 'LUOGO NASCITA MOGLIE', 'DATA NASCITA MOGLIE', 'DATA MATRIMONIO', 'NRO ATTO', "ANNO DELL'ATTO", 'PARTE', 'SEZIONE', 'IMMAGINE'],
            ATTO_NASCITA: ['NOME', 'COGNOME', 'LUOGO NASCITA', 'DATA NASCITA', 'NRO ATTO', "ANNO DELL'ATTO", 'PARTE', 'SEZIONE', 'IMMAGINE'],
            ATTO_CITTADINANZA: ['NOME', 'COGNOME', 'LUOGO NASCITA', 'DATA NASCITA', 'DATA RILASCIO', 'NRO ATTO', "ANNO DELL'ATTO", 'PARTE', 'SEZIONE', 'IMMAGINE'],
            ATTO_MORTE: ['NOME', 'COGNOME', 'LUOGO NASCITA', 'DATA NASCITA', 'DATA DI MORTE', 'NRO ATTO', "ANNO DELL'ATTO", 'PARTE', 'SEZIONE', 'IMMAGINE']
        }
    }

    static get TIPO_DOCUMENTO() {
        return {
            FOGLIO_FAMIGLIA: 2,
            STATO_CIVILE: 3,
            SCHEDA_INDIVIDUALE: 7,
            CARTELLINO_IDENTITA: 8,
            SCHEDA_INDIVIDUALI_ELIMINATE: 10,
            TUTTE: 11
        }
    }

    static get TIPO_ATTO(): { [key: string]: number } {
        return {
            NASCITA: 4,
            MATRIMONIO: 5,
            CITTADINANZA: 6,
            MORTE: 9
        }
    }

    static get TYPES_EXT_SHEET() {
        return ['xls', 'xlsx', 'xlsm'];
    }

    static get TYPES_EXT_IMG() {
        return ['png', 'jpg', 'jpeg', 'pdf'];
    }

    static get ICONS_CUSTOM() {
        return [
            'filter',
            'filter-square',
            'filter-square-fill',
            'person-fill-add',
            'arrows-angle-expand'
        ];
    }

    static get TIPO_RUOLO() {
        return {
            ADMINISTRATORE: 1,
            UTENTE_AVANZATO: 2,
            UTENTE_NORMALE: 3
        }
    }

    static get MSG() {
        return {
            ERROR: {
                NOT_FOUND: 'Contenuto non trovato'
            }
        }
    }

    static get PAGES() {
        return {
            RICERCA_DOCUMENTI: 'ricerca-documenti',
            GESTIONE_ANAGRAFICHE: 'gestione-anagrafiche',
            GESTIONE_DOCUMENTI: 'gestione-documenti',
            GESTIONE_UTENTI: 'gestione-utenti',
            UPLOAD_DOCUMENTI: 'upload-documenti'
        }
    }

}