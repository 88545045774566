import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-img-item',
  templateUrl: './img-item.component.html',
  styleUrls: ['./img-item.component.scss']
})
export class ImgItemComponent {
  @Input() file: any;
  @Input() progress: number = 0;
  @Input() maxWidth: string | undefined;
  @Output() outSelect = new EventEmitter();
  constructor() { }

  selectImg(): void {
    this.outSelect.emit(this.file);
  }

  getSize(): string {
    if (!this.file?.size) return '';

    let textSize = '';
    const sizeKB = this.file?.size / 1024;

    if (this.file?.size < (1 * 1024 * 1024)) {
      textSize = `${(sizeKB).toFixed(2)} KB`;
    } else {
      textSize = `${(sizeKB / 1024).toFixed(2)} MB`;
    }

    return textSize;
  }

  getProgress(): any {
    return {
      'width': `${this.progress}%`
    }
  }

  isPdf(nameFile: string): boolean {
    return nameFile.includes('.pdf');
  }

}
