import { Observable, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { LocalStorageService } from './localstorage.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Injectable({
    providedIn: 'root',
})
export class RequestInterceptorService implements HttpInterceptor {
    constructor(
        private _localStorageService: LocalStorageService,
        private _router: Router,
        private _authService: AuthService
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this._localStorageService.get('accessToken') || '';
        if (token && !request.url.includes('login') && request.url.includes(environment.path)) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token}`
                }
            });
        }

        const $handle = next.handle(request).pipe(tap(_e => {
            if (_e instanceof HttpResponse) {
            }
        }, (_e: any) => {
            if (_e instanceof HttpErrorResponse) {
                if (_e.status === 401) {
                    this._localStorageService.clear();
                    this._router.navigate(['login']);
                    this._authService.enabledLoaderAuth = false;
                    this._authService.intervalRefreshTokenSub.unsubscribe();
                }
            }
        }));
        return $handle;
    }
}
